import request from '@/auth/jwt/request'

/**
 * @description 员工管理 -- 员工管理列表
 * @param {Object} param params {Object} 传值参数
 */
export const staffListApi = params => { return request('staff/list', 'get', params) }

/**
 * @description 员工管理 -- 所属部门下拉
 * @param {Object} param params {Object} 传值参数
 */
export const staffDepartSelectApi = params => { return request('depart/list', 'get', params) }

/**
 * @description 员工管理 -- 所属项目下拉
 * @param {Object} param params {Object} 传值参数
 */
export const staffProjectSelectApi = params => { return request('pull-down/projectSelect', 'get', params) }

/**
 * @description 员工管理 -- 增加修改员工
 * id = 0 新增
 * @param {Object} param params {Object} 传值参数
 */
export const staffSaveApi = data => { return request('staff/save', 'post', {}, data) }

/**
 * @description 员工管理 -- 员工状态管理
 * @param {Object} param params {Object} 传值参数
 */
export const staffStatusApi = params => { return request('staff/status', 'get', params) }

/**
 * @description 员工管理 -- 员工详情
 * @param {Object} param params {Object} 传值参数
 */
export const staffInfoApi = params => { return request('staff/info', 'get', params) }
/**
 * @description 员工管理 -- 删除员工
 * { id = x }
 */
export const staffDeleteApi = data => { return request('staff/delete', 'delete', data) }

/**
 * @description 员工管理 -- 名片管理列表
 * @param {Object} param params {Object} 传值参数
 */
 export const staffCallcardListApi = params => { return request('card/list', 'get', params) }