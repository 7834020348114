<template>
  <div>
    <b-card title="名片基本信息">
      <b-card-text>
        <validation-observer ref="refFormObserver">
          <b-form class="mb-1">
            <b-row>
              <!-- name -->
              <b-col md="4">
                <b-form-group label="名片名称" label-for="name">
                  <validation-provider name="产品名称" #default="{ errors }" rules="required">
                    <b-form-input
                      id="name" :state="errors.length > 0 ? false:null"
                      v-model="cardInfo.name"
                      trim
                      placeholder="例如: 刘德华的名片"
                    />
                    <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- staffId -->
              <b-col md="4">
                <validation-provider name="关联员工" rules="required" #default="{ errors }">
                  <b-form-group label="关联员工" label-for="staffId" :state="errors.length > 0 ? false:null">
                    <v-select
                      v-model="cardInfo.staffId"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="staffOptions" :clearable="true" label="name"
                      :reduce="val => val.id"
                    >
                      <template v-slot:no-options>没有找到相关信息</template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- companyName -->
              <b-col md="4">
                <b-form-group label="名片公司" label-for="companyName">
                  <validation-provider name="名片公司" #default="{ errors }" rules="required">
                    <b-form-input
                      id="companyName" :state="errors.length > 0 ? false:null"
                      v-model="cardInfo.companyName"
                      trim
                      placeholder="例如: 产业园"
                    />
                    <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- address -->
              <b-col md="4">
                <b-form-group label="名片地址" label-for="address">
                  <validation-provider name="名片地址" #default="{ errors }" rules="required">
                    <b-form-input
                      id="address" :state="errors.length > 0 ? false:null"
                      v-model="cardInfo.address"
                      trim
                      placeholder="例如: 北京市"
                    />
                    <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- positionName -->
              <b-col md="4">
                <b-form-group label="名片职位" label-for="positionName">
                  <validation-provider name="名片职位" #default="{ errors }" rules="required">
                    <b-form-input
                      id="positionName" :state="errors.length > 0 ? false:null"
                      v-model="cardInfo.positionName"
                      trim
                      placeholder="例如: 总经理"
                    />
                    <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- weixinName -->
              <b-col md="4">
                <b-form-group label="微信号" label-for="weixinName">
                    <b-form-input
                      id="weixinName"
                      v-model="cardInfo.weixinName"
                      trim
                      placeholder="例如: LIUDEHUA123"
                    />
                </b-form-group>
              </b-col>

              <!-- typeId -->
              <b-col md="4" v-if="cardInfo.type === 'project'">
                <validation-provider name="关联项目" rules="required" #default="{ errors }">
                  <b-form-group label="关联项目" label-for="typeId" :state="errors.length > 0 ? false:null">
                    <v-select
                      v-model="cardInfo.typeId"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="projectOptions" :clearable="true" label="name"
                      :reduce="val => val.id"
                    >
                      <template v-slot:no-options>没有找到相关信息</template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="4" v-else>
                <validation-provider name="关联服务" rules="required" #default="{ errors }">
                  <b-form-group label="关联服务" label-for="typeId" :state="errors.length > 0 ? false:null">
                    <v-select
                      v-model="cardInfo.typeId"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="serviceOptions" :clearable="true" label="name"
                      :reduce="val => val.id"
                    >
                      <template v-slot:no-options>没有找到相关信息</template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- aliasName -->
              <b-col md="4">
                <b-form-group>
                  <label for="aliasName">项目别名</label>
                  <small class="text-muted"> 注. <i>用于对外推广名称</i></small>
                  <b-form-input id="aliasName" v-model="cardInfo.aliasName" trim placeholder="例如: 商务港" />
                </b-form-group>
              </b-col>

              <!-- products -->
              <b-col md="4" v-if="cardInfo.type === 'project'">
                <b-form-group label="关联项目产品" label-for="products">
                  <v-select
                    v-model="cardInfo.products"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="productOptions" :clearable="true" label="name"
                    multiple
                    :reduce="val => val.id"
                  >
                    <template v-slot:no-options>没有找到相关信息</template>
                  </v-select>
                </b-form-group>
              </b-col>

              <!-- projectPics -->
              <b-col md="4" v-if="cardInfo.type === 'project'">
                <b-form-group label="关联项目图册" label-for="projectPics">
                  <v-select
                    v-model="cardInfo.projectPics"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="picOptions" :clearable="true" label="name"
                    multiple
                    :reduce="val => val.id"
                  >
                    <template v-slot:no-options>没有找到相关信息</template>
                  </v-select>
                </b-form-group>
              </b-col>

              <!-- projectVideos -->
              <b-col md="4" v-if="cardInfo.type === 'project'">
                <b-form-group label="关联项目视频" label-for="projectVideos">
                  <v-select
                    v-model="cardInfo.projectVideos"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="videoOptions" :clearable="true" label="name"
                    multiple
                    :reduce="val => val.id"
                  >
                    <template v-slot:no-options>没有找到相关信息</template>
                  </v-select>
                </b-form-group>
              </b-col>

              <!-- status -->
              <b-col md="4">
                <validation-provider name="产品状态" #default="{ errors }" rules="required">
                  <b-form-group label="产品状态" label-for="status" :state="errors.length > 0 ? false:null">
                    <b-form-radio-group id="status" class="col-form-label" v-model="cardInfo.status" :options="statusOptions" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col class="d-flex justify-content-end align-items-end">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-1"
                  v-if="$can('save', 'extension-card')"
                  @click="submit"
                >
                  {{ cardInfo.id>0 ? '保存' : '添加' }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="button"
                  variant="outline-primary"
                  class="mr-1"
                  @click="fetchData"
                >
                  重置
                </b-button>
              </b-col>

            </b-row>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-card>

    <b-card v-if="cardObject.sysProjectPics.length">
      <b-card-title class="p-0 m-0">关联图册</b-card-title>
    </b-card>

    <b-row class="d-flex" v-if="cardObject.sysProjectPics.length">
      <b-col
        v-for="(item,index) in cardObject.sysProjectPics"
        :key="index"
        md="4"
        sm="6"
      >
        <b-card no-body>
          <b-card-header class="p-0 pb-1">
          <swiper
            class="swiper-navigations image-border"
            :options="swiperOptions"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          >
            <swiper-slide v-for="(ite,idx) in item.contents" :key="idx">
              <b-img :src="ite" fluid class="w-100" />
            </swiper-slide>

            <div
              slot="pagination"
              class="swiper-pagination"
            />
          </swiper>
          </b-card-header>
          <b-card-body>
            <b-card-title>{{ item.name }}</b-card-title>
            <b-card-sub-title>{{item.createdAt}}</b-card-sub-title>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-card v-if="cardObject.sysProjectVideos.length">
      <b-card-title class="p-0 m-0">关联视频</b-card-title>
    </b-card>

    <b-row class="d-flex" v-if="cardObject.sysProjectVideos.length">
      <b-col
        v-for="(item,index) in cardObject.sysProjectVideos"
        :key="index"
        md="4"
        sm="6"
      >
        <b-card
          :img-src="item.pic"
          img-alt="Card image cap"
          img-top
          no-body
        >
          <b-card-body>
            <b-card-title>{{ item.name }}</b-card-title>
            <b-card-sub-title>{{item.createdAt}}</b-card-sub-title>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-card v-if="cardObject.sysProjectProductList.length">
      <b-card-title class="p-0 m-0">关联产品</b-card-title>
    </b-card>

    <b-row class="d-flex" v-if="cardObject.sysProjectProductList.length">
      <b-col
        v-for="(item,index) in cardObject.sysProjectProductList"
        :key="index"
        md="4"
        sm="6"
      >
        <b-card
          :img-src="item.pic"
          img-alt="Card image cap"
          img-top
          no-body
        >
          <b-card-body>
            <b-card-title>{{ item.name }}</b-card-title>
            <b-card-sub-title>{{item.createdAt}}</b-card-sub-title>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { ref, onMounted, watch, reactive } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import router from '@/router'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required, integer } from '@validations'

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

import {
  callcardInfoApi,
  callcardSaveApi,

  callcardStaffListApi,
  callcardProjectListApi,
  callcardprojectInfoApi,
} from '@/api/callcard'

import { staffInfoApi } from '@/api/staff'

export default {
  directives: {
    Ripple,
  },
  components: {
    vSelect,
    formValidation,
    ValidationProvider,
    ValidationObserver,

    Swiper,
    SwiperSlide,
  },

  setup(props, { root, emit }) {
    /* 必要组件加载区域 */

    // 权限组件 弹出确认组件
    const { $can } = root
    // 消息提醒组件
    const toast = useToast()

    /* 组件页面数据准备区域，包括表单，下拉选择项，数据整理操作等 */

    // 表单 dom 对象
    const refFormObserver = ref(null)

    const flag = ref(false)

    const cardInfo = reactive({
      id: 0,
      staffId: null,
      address: '',
      aliasName: '',
      companyName: '',
      name: '',
      positionName: '',
      status: true,
      type: 'project',
      typeId: null,
      weixinName: '',
      products: [],
      projectPics: [],
      projectVideos: [],
    })

    // 判断是路由情况，区分编辑还是新增
    if (router.currentRoute.params.id > 0) {
      cardInfo.id = Number(router.currentRoute.params.id)
    } else { cardInfo.id = 0 }

    if (router.currentRoute.params.user > 0) {
      cardInfo.staffId = Number(router.currentRoute.params.user)
    }

    // 带入员工信息
    if (cardInfo.id === 0 && cardInfo.staffId > 0) {
      staffInfoApi({ id: cardInfo.staffId })
        .then(res => {
          cardInfo.positionName = res.data.position
          cardInfo.weixinName = res.data.wxworkUserid
          cardInfo.typeId = res.data.defaultProject
        })
        .catch(res => {})
    }

    // 状态参数
  const statusOptions = [
    { text: '启用', value: true, },
    { text: '禁用', value: false, },
  ]

  // 名片type
  // const typeOptions = [
  //   { text: '项目', value: 'project' },
  //   { text: '服务', value: 'doing_business_server' },
  // ]

  const staffOptions = ref([])
  const projectOptions = ref([])
  const picOptions = ref([])
  const videoOptions = ref([])
  const productOptions = ref([])
  const serviceOptions = ref([])

  const cardObject = reactive({
    sysProjectPics: [],
    sysProjectProductList: [],
    sysProjectVideos: [],
  })

  const swiperOptions = {
    pagination: { el: '.swiper-pagination' },
  }

    /* 接口调用区域 */

    // 获取名片详细信息
    const fetchData = () => {
      callcardInfoApi({ id: cardInfo.id })
        .then(res => {
          cardInfo.id = res.data.id
          cardInfo.staffId = res.data.staffId
          cardInfo.address = res.data.address
          cardInfo.aliasName = res.data.aliasName
          cardInfo.companyName = res.data.companyName
          cardInfo.name = res.data.name
          cardInfo.positionName = res.data.positionName
          cardInfo.status = res.data.status
          cardInfo.type = res.data.type
          cardInfo.typeId = res.data.typeId
          cardInfo.weixinName = res.data.weixinName

          cardInfo.products = res.data.products
          cardInfo.projectPics = res.data.projectPics
          cardInfo.projectVideos = res.data.projectVideos

          cardObject.sysProjectPics = res.data.sysProjectPics || []
          cardObject.sysProjectProductList = res.data.sysProjectProductList || []
          cardObject.sysProjectVideos = res.data.sysProjectVideos || []

        })
        .catch(err => {
          toast({
            component: ToastificationContent,
            props: { title: '获取名片基本信息失败', text: err.msg, icon: 'AlertTriangleIcon', variant: 'danger' },
          })
        })
    }

    // 获取项目列表
    const getProjectList = () => {
      callcardProjectListApi()
        .then(res => {
          projectOptions.value = res.data
        })
        .catch(err => {
          toast({
            component: ToastificationContent,
            props: { title: '获取项目列表失败', text: err.msg, icon: 'AlertTriangleIcon', variant: 'danger' },
          })
        })
    }

    // 获取员工列表
    const getStaffList = () => {
      callcardStaffListApi()
        .then(res => { staffOptions.value = res.data })
        .catch(err => {
          toast({
            component: ToastificationContent,
            props: { title: '获取员工列表失败', text: err.msg, icon: 'AlertTriangleIcon', variant: 'danger' },
          })
        })
    }

    // 获取项目详情
    const getprojectInfo = id => {
      callcardprojectInfoApi({ id })
        .then(res => {
          picOptions.value = res.data.sysProjectPics
          videoOptions.value = res.data.sysProjectVideos
          productOptions.value = res.data.sysProjectProductList

          if(cardInfo.id === 0) {
            cardInfo.projectPics = []
            res.data.sysProjectPics.map(item => {
              cardInfo.projectPics.push(item.id)
            })
            cardInfo.projectVideos = []
            res.data.sysProjectVideos.map(item => {
              cardInfo.projectVideos.push(item.id)
            })
            cardInfo.products = []
            res.data.sysProjectProductList.map(item => {
              cardInfo.products.push(item.id)
            })
          } else {
            if (flag.value) {
              cardInfo.projectPics = []
              res.data.sysProjectPics.map(item => {
                cardInfo.projectPics.push(item.id)
              })
              cardInfo.projectVideos = []
              res.data.sysProjectVideos.map(item => {
                cardInfo.projectVideos.push(item.id)
              })
              cardInfo.products = []
              res.data.sysProjectProductList.map(item => {
                cardInfo.products.push(item.id)
              })
            } else flag.value = true
          }
        })
        .catch(err => {
          toast({
            component: ToastificationContent,
            props: { title: '获取项目资料失败', text: err.msg, icon: 'AlertTriangleIcon', variant: 'danger' },
          })
        })
    }

    const submit = () => {
      refFormObserver.value.validate().then(success => {
        if (success) {
          callcardSaveApi(cardInfo)
            .then(res => {
              if(res.data && res.data.id) {
                cardInfo.id = res.data.id
              }
              // 2022-5-14 修改为点击保存返回
              // fetchData()
              router.back()
              // fetchData()
              toast({
                component: ToastificationContent,
                props: { title: '更新名片信息成功', icon: 'CheckCircleIcon', variant: 'success' },
              })
            })
            .catch(err => {
              toast({
                component: ToastificationContent,
                props: { title: '更新名片信息失败', text: err.msg, icon: 'AlertTriangleIcon', variant: 'danger' },
              })
            })
        }
      })
    }

    /* 组件内部逻辑处理，生命周期问题处理区域 */

    // 处理基本下拉信息加载，权限判断等
    onMounted(() => {
      if (cardInfo.id != 0) {
        fetchData()
      }
      getProjectList()
      getStaffList()
    })

    watch(() => cardInfo.id, () => {
      fetchData()
      getProjectList()
      getStaffList()
    })

    watch(() => cardInfo.typeId, () => {
      if (cardInfo.type === 'project' && cardInfo.typeId > 0) {
        getprojectInfo(cardInfo.typeId)
      }
      if (!cardInfo.typeId) {
        cardInfo.projectPics = []
        cardInfo.projectVideos = []
        cardInfo.products = []
        picOptions.value = []
        videoOptions.value = []
        productOptions.value = []
      }
    })

    /* 分割线 */

    return {
      cardInfo,
      statusOptions,
      // typeOptions,
      swiperOptions,

      staffOptions,
      projectOptions,
      picOptions,
      videoOptions,
      productOptions,
      serviceOptions,

      cardObject,

      fetchData,
      submit,

      required,
      integer,

      refFormObserver,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/pages/page-knowledge-base.scss';
.image-border {
  border-radius: 0.428rem 0.428rem 0 0;
}
</style>